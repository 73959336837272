<template>
  <div class="delivery-order-container">
    <my-nav-bar
      title="配送订单"
      left-text="返回"
      left-arrow
      :right-text="clients.length > 0 ? '结算' : ''"
      @click-right="handleNavRightBtnClick"
    />
    <div v-if="clients.length > 0" class="delivery-order-container__search-bar">
      <form action="/">
        <van-search
          v-model="clientName"
          placeholder="请输入客户名称"
          clearable
          @search="handleSearch"
        />
      </form>
    </div>
    <div :class="clients.length > 0 ? 'app-main-search-container' : 'app-main-container'">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div class="delivery-order-container__content" :class="{ 'footer-button': waitCount > 0 }">
        <div v-if="clients.length > 0" class="delivery-order-tips">提示：往左边滑动导航、退货</div>
        <div v-if="unpaidReceiveAmount > 0 || aodAmount > 0" style="margin-bottom: 10px;">
          <van-cell v-if="aodAmount > 0" value-class="aod-amount" title="退货差价" :value="`￥${aodAmount}`" is-link @click="toAodListView()" />
          <van-cell v-if="unpaidReceiveAmount > 0" title="签单收款" value-class="aod-amount" :value="`￥${unpaidReceiveAmount}`" is-link center @click="$router.push('/unpaid-receive')" />
        </div>
        <div>
          <div v-for="(client, index) in clients" :key="index" class="delivery-order-container__content__cell">
            <van-swipe-cell class="client-swipe-cell">
              <van-cell class="client-cell" center is-link @click="toPaymentView(client)">
                <template #title>
                  <span>{{ client.name }}</span>
                  <van-tag v-if="client.is_urgent" class="client-cell__tag" type="danger">加急</van-tag>
                  <!-- <van-tag v-if="client.payment_id" class="client-cell__tag" type="success">已支付</van-tag> -->
                  <template v-if="client.payment_info">
                    <van-tag v-if="client.payment_info.wxpay_amount > 0" class="client-cell__tag" type="success">线上支付</van-tag>
                    <van-tag v-if="client.payment_info.cash_amount > 0" class="client-cell__tag" type="warning">现金</van-tag>
                    <van-tag v-if="client.payment_info.trans_amount > 0" class="client-cell__tag" color="#B0171F">转账</van-tag>
                    <van-tag v-if="client.payment_info.unpaid_amount < 0" class="client-cell__tag" type="primary">签单</van-tag>
                  </template>
                  <van-tag v-else-if="client.timeout_count > 0" class="client-cell__tag" type="danger">{{ `${client.timeout_count}张已超时` }}</van-tag>
                </template>
              </van-cell>
              <van-collapse v-if="client.has_print_info || client.has_remark" v-model="client.show_print_info" :border="false">
                <van-collapse-item title="查看收货信息">
                  <div v-for="(order, index2) in client.orders" :key="index2">
                    <template v-if="order.print_info">
                      <span>（{{ order.num }}）</span>
                      <span v-if="order.print_info.client_address" class="print-info-item">收货地址：{{ order.print_info.client_address }}</span>
                      <span v-if="order.print_info.client_name" class="print-info-item">收货人：{{ order.print_info.client_name }}</span>
                      <span v-if="order.print_info.client_phone">联系电话：{{ order.print_info.client_phone }}</span>
                    </template>
                  </div>
                </van-collapse-item>
                <van-collapse-item v-if="client.has_remark" title="查看备注">
                  <div v-for="(order, index2) in client.orders" :key="index2">
                    <span v-if="order.remark" class="print-info-item">客户备注：{{ order.remark }}</span>
                    <span v-if="order.store_remark">门店备注：{{ order.store_remark }}</span>
                  </div>
                </van-collapse-item>
              </van-collapse>
              <template #right>
                <div style="height: 100%">
                  <van-button square type="info" @click="handleNavigation(client)">导航</van-button>
                  <van-button square type="primary" text="详情" @click="toListView(client)" />
                </div>
              </template>
            </van-swipe-cell>
          </div>
        </div>
      </div>
    </div>
    <div v-if="waitCount > 0" class="fixed-bottom-btn">
      <van-button square block :text="`开始配送（共${waitCount}个客户，${orderCount}张订单）`" @click="begin" />
    </div>
    <el-amap
      v-if="showMap"
      :amap-manager="amapManager"
      :vid="'amap-vue'"
      :zoom="18"
      :expand-zoom-range="true"
      :center="[saveTude.longitude, saveTude.latitude]"
      :events="events"
      class="el-map-css"
    >
      <el-amap-marker
        :position="[saveTude.longitude, saveTude.latitude]"
      />
    </el-amap>
    <van-button v-if="showMap" type="info" icon="arrow-left" class="location-map-close-btn" @click="showMap = false">返回</van-button>
    <div v-if="showFixedBtn" class="fixed-btn-container" @click="toPromoteSalesDetailView">
      <div class="fixed-btn">
        <i class="mhj-icon-zhuangche-xian" />
        <span class="fixed-btn-text">特惠购</span>
      </div>
    </div>
  </div>
</template>

<script>
import deliveryOrderApi from '@/api/delivery_order'
import { getSDKConfig } from '@/api/wechat'
import { getPromoteSalesPlanCount } from '@/api/promote-sales'
import myNavBar from '@/components/my-nav-bar'
import wx from 'weixin-js-sdk'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
export default {
  name: 'DeliveryOrder',
  components: { myNavBar },
  data() {
    return {
      clientName: '',
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      clients: [],
      waitCount: 0,
      orderCount: 0,
      showEmpty: false,
      saveTude: { longitude: 0, latitude: 0 }, // 保存经纬度
      detailAddress: '',
      showMap: false,
      amapManager: new AMapManager(),
      events: {
        'click': (e) => {
          //  console.log(e.lnglat)
          //  const { lng, lat } = e.lnglat
          //  this.markers.push([lng, lat])
        }
      },
      customerAddr: '',
      currentAddr: '',
      showFixedBtn: false,
      unpaidReceiveAmount: 0,
      aodAmount: 0
    }
  },
  computed: {
    notFinishedCount() {
      let notFinishedCount = 0
      this.clients.map(client => {
        if (!client.payment_id) { notFinishedCount++ }
      })
      return notFinishedCount
    }
  },
  created() {
    // 获取访问的user-agent
    var ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase()
    // // 判断user-agent
    var isWX = /MicroMessenger/i.test(ua)
    // if (!isWX) {
    //   this.fail('请使用微信打开')
    //   return
    // }
    if (isWX) {
      var isIOS = /(iPhone|iPad|iPod|iOS)/i.test(ua) // 苹果家族
      var isAndroid = /(android|nexus)/i.test(ua) // 安卓家族
      const split_path = location.href.split('/#/')[1]
      let path
      if (split_path.indexOf('?') === -1) {
        path = split_path
      } else {
        path = split_path.split('?')[0]
      }
      let params
      if (isIOS) {
        params = { platform: 'iOS', path }
      } else if (isAndroid) {
        params = { platform: 'Android', path }
      }
      getSDKConfig(params).then(res => {
        this.sdkConfig = res.data
        const { appId, debug, jsApiList, nonceStr, signature, timestamp } = this.sdkConfig
        wx.config({
          debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList // 必填，需要使用的JS接口列表
        })
      })
    }

    this.getClients()

    getPromoteSalesPlanCount().then(res => {
      this.showFixedBtn = res.data.count > 0
    })
  },
  methods: {
    begin() {
      this.beginLoad()
      deliveryOrderApi.begin().then(res => {
        this.endLoad()
        this.page = 0
        this.clients = []
        this.finished = false
        this.getClients()
      })
    },
    getClients() {
      this.beginLoad()
      this.page++
      const params = {
        page: this.page,
        limit: this.limit,
        client_name: this.clientName,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      deliveryOrderApi.clients(params).then(res => {
        res.data.list.forEach(c => {
          c.show_print_info = []
        })
        this.clients = this.clients.concat(res.data.list)
        this.unpaidReceiveAmount = res.data.unpaid_receive_amount
        this.aodAmount = res.data.aod_amount
        this.waitCount = res.data.wait_count
        this.orderCount = res.data.order_count
        this.loading = false
        this.showEmpty = this.clients.length === 0 && Number(this.unpaidReceiveAmount) === 0
        this.finished = res.data.list.length < this.limit
        this.endLoad()
      })
    },
    handleSearch() {
      this.page = 0
      this.clients = []
      this.finished = false
      this.getClients()
    },
    toPaymentView(client) {
      // 付款
      this.$router.push({
        path: '/delivery-order-payment',
        query: {
          client_id: client.id,
          payment_id: client.payment_id,
          bill_reject_id: this.$route.query.bill_reject_id,
          pay_order_num: client.orders[0]['num']
        }
      })
    },
    toListView(client) {
      this.$router.push({
        path: '/delivery-order-goods',
        query: {
          client_id: client.id,
          client_name: client.name,
          bill_reject_id: this.$route.query.bill_reject_id
        }
      })
    },
    handleNavRightBtnClick() {
      if (this.clients.length === 0) { return }
      if (this.notFinishedCount > 0) {
        this.confirm('还有' + this.notFinishedCount + '个客户未支付，确定要结算吗？').then(() => {
          this.toBillView()
        })
      } else { this.toBillView() }
    },
    toBillView() {
      this.$router.push({
        path: '/bill',
        query: {
          type: 'settlement',
          bill_reject_id: this.$route.query.bill_reject_id
        }
      })
    },
    isnavigation(client) { // 跳转至高德App
      let Url = ''
      const { longitude, latitude } = this.saveTude
      Url = `https://uri.amap.com/navigation?from=${longitude},${latitude},${this.currentAddr}&to=${client.longitude},${client.latitude},${this.customerAddr}&callnative=1`
      location.href = Url
    },
    handleNavigation(client) {
      this.confirm('如果导航位置不正确，请与IT部反馈', '提示').then(() => {
        const { name, province, city, district, address, longitude, latitude } = client
        this.customerAddr = province + city + district + address
        var ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase()
        // 判断user-agent
        var isWX = /MicroMessenger/i.test(ua)
        // 由于是微信小程序下单获取的客户定位坐标格式是gcj02，微信地图跟高德地图都是gcj02坐标系，所以无需转坐标系
        if (isWX) {
          wx.openLocation({
            latitude: Number(latitude), // 纬度，范围为-90~90，负数表示南纬
            longitude: Number(longitude), // 经度，范围为-180~180，负数表示西经
            scale: 15, // 缩放比例
            name: name,
            address: province + city + district + address,
            success: function(r) {

            }
          })
        } else { // 浏览器
          lazyAMapApiLoaderInstance.load().then(() => {
            AMap.plugin('AMap.Geolocation', res => {
              var geolocation = new AMap.Geolocation({
              // 设置定位超时时间，默认：无穷大
                timeout: 10000,
                // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                buttonOffset: new AMap.Pixel(10, 20),
                //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                zoomToAccuracy: true,
                //  定位按钮的排放位置,  RB表示右下
                buttonPosition: 'RB'
              })

              geolocation.getCurrentPosition((status, result) => {
                if (status === 'complete') {
                  onComplete(result)
                } else {
                  onError(result)
                }
              })

              const onComplete = (data) => {
              // data是具体的定位信息 position是经纬度 formattedAddress当前地址信息
                this.currentAddr = data.formattedAddress
                const { lng, lat } = data.position
                const params = {
                  latitude: lat,
                  longitude: lng
                }
                this.saveTude = params
                this.isnavigation(client)
              }
              const onError = (data) => {
              // 定位出错
              // this.isnavigation(client)
                this.fail('授权失败')
              }
            })
          })
        // this.showMap = true
        }
      })
    },
    checkPrintInfo(client) {
      let hasPrintInfo = false
      client.orders.forEach(order => {
        if (order.print_info) {
          hasPrintInfo = true
        }
      })
      return hasPrintInfo
    },
    checkRemark(client) {
      let hasRemark = false
      client.orders.forEach(order => {
        if (order.remark || order.store_remark) {
          hasRemark = true
        }
      })
      return hasRemark
    },
    toPromoteSalesDetailView() {
      this.$router.push({
        path: '/promote-sales-order'
      })
    },
    toAodListView() {
      this.$router.push({
        path: '/aod-list'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .delivery-order-container {
    .el-map-css{
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 999;
    }
    .location-map-close-btn{
     height: 30px;
     position: fixed;
     left: 25px;
     top: 25px;
     z-index: 9999;
     padding: 0 10px;
     opacity: .8;
   }
    &__search-bar {
      z-index: 1;
      position: fixed;
      top: 46px;
      left: 0;
      right: 0;
    }
    &__content {
      padding: 15px 15px 5px 15px;
      &__cell {
        padding-bottom: 10px;
      }
    }
    .client-swipe-cell {
      .client-cell {
        // height: 50px;
        &__tag {
          margin-left: 5px;
        }
      }
      .van-button {
        height: 100%;
      }
    }
  }
  .delivery-order-tips {
    padding-bottom: 10px;
    color: #A3A3A3;
  }
  .footer-button {
    padding-bottom: 55px;
  }
  .fixed-btn-container {
    position: fixed;
    bottom: 55px;
    right: 10px;
    width: 55px;
    height: 55px;
    color: #fff;
    background-color: #07c160;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fixed-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fixed-btn-text {
    font-size: 12px;
  }
  .print-info-item {
    margin-right: 15px;
  }
  .aod-amount {
    color: #ee0a24;
  }
</style>
