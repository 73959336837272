var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delivery-order-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "配送订单",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": _vm.clients.length > 0 ? '结算' : ''
    },
    on: {
      "click-right": _vm.handleNavRightBtnClick
    }
  }), _vm.clients.length > 0 ? _c('div', {
    staticClass: "delivery-order-container__search-bar"
  }, [_c('form', {
    attrs: {
      "action": "/"
    }
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入客户名称",
      "clearable": ""
    },
    on: {
      "search": _vm.handleSearch
    },
    model: {
      value: _vm.clientName,
      callback: function callback($$v) {
        _vm.clientName = $$v;
      },
      expression: "clientName"
    }
  })], 1)]) : _vm._e(), _c('div', {
    class: _vm.clients.length > 0 ? 'app-main-search-container' : 'app-main-container'
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "delivery-order-container__content",
    class: {
      'footer-button': _vm.waitCount > 0
    }
  }, [_vm.clients.length > 0 ? _c('div', {
    staticClass: "delivery-order-tips"
  }, [_vm._v("提示：往左边滑动导航、退货")]) : _vm._e(), _vm.unpaidReceiveAmount > 0 || _vm.aodAmount > 0 ? _c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm.aodAmount > 0 ? _c('van-cell', {
    attrs: {
      "value-class": "aod-amount",
      "title": "退货差价",
      "value": "\uFFE5".concat(_vm.aodAmount),
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toAodListView();
      }
    }
  }) : _vm._e(), _vm.unpaidReceiveAmount > 0 ? _c('van-cell', {
    attrs: {
      "title": "签单收款",
      "value-class": "aod-amount",
      "value": "\uFFE5".concat(_vm.unpaidReceiveAmount),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/unpaid-receive');
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', _vm._l(_vm.clients, function (client, index) {
    return _c('div', {
      key: index,
      staticClass: "delivery-order-container__content__cell"
    }, [_c('van-swipe-cell', {
      staticClass: "client-swipe-cell",
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('div', {
            staticStyle: {
              "height": "100%"
            }
          }, [_c('van-button', {
            attrs: {
              "square": "",
              "type": "info"
            },
            on: {
              "click": function click($event) {
                return _vm.handleNavigation(client);
              }
            }
          }, [_vm._v("导航")]), _c('van-button', {
            attrs: {
              "square": "",
              "type": "primary",
              "text": "详情"
            },
            on: {
              "click": function click($event) {
                return _vm.toListView(client);
              }
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      staticClass: "client-cell",
      attrs: {
        "center": "",
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toPaymentView(client);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('span', [_vm._v(_vm._s(client.name))]), client.is_urgent ? _c('van-tag', {
            staticClass: "client-cell__tag",
            attrs: {
              "type": "danger"
            }
          }, [_vm._v("加急")]) : _vm._e(), client.payment_info ? [client.payment_info.wxpay_amount > 0 ? _c('van-tag', {
            staticClass: "client-cell__tag",
            attrs: {
              "type": "success"
            }
          }, [_vm._v("线上支付")]) : _vm._e(), client.payment_info.cash_amount > 0 ? _c('van-tag', {
            staticClass: "client-cell__tag",
            attrs: {
              "type": "warning"
            }
          }, [_vm._v("现金")]) : _vm._e(), client.payment_info.trans_amount > 0 ? _c('van-tag', {
            staticClass: "client-cell__tag",
            attrs: {
              "color": "#B0171F"
            }
          }, [_vm._v("转账")]) : _vm._e(), client.payment_info.unpaid_amount < 0 ? _c('van-tag', {
            staticClass: "client-cell__tag",
            attrs: {
              "type": "primary"
            }
          }, [_vm._v("签单")]) : _vm._e()] : client.timeout_count > 0 ? _c('van-tag', {
            staticClass: "client-cell__tag",
            attrs: {
              "type": "danger"
            }
          }, [_vm._v(_vm._s("".concat(client.timeout_count, "\u5F20\u5DF2\u8D85\u65F6")))]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }), client.has_print_info || client.has_remark ? _c('van-collapse', {
      attrs: {
        "border": false
      },
      model: {
        value: client.show_print_info,
        callback: function callback($$v) {
          _vm.$set(client, "show_print_info", $$v);
        },
        expression: "client.show_print_info"
      }
    }, [_c('van-collapse-item', {
      attrs: {
        "title": "查看收货信息"
      }
    }, _vm._l(client.orders, function (order, index2) {
      return _c('div', {
        key: index2
      }, [order.print_info ? [_c('span', [_vm._v("（" + _vm._s(order.num) + "）")]), order.print_info.client_address ? _c('span', {
        staticClass: "print-info-item"
      }, [_vm._v("收货地址：" + _vm._s(order.print_info.client_address))]) : _vm._e(), order.print_info.client_name ? _c('span', {
        staticClass: "print-info-item"
      }, [_vm._v("收货人：" + _vm._s(order.print_info.client_name))]) : _vm._e(), order.print_info.client_phone ? _c('span', [_vm._v("联系电话：" + _vm._s(order.print_info.client_phone))]) : _vm._e()] : _vm._e()], 2);
    }), 0), client.has_remark ? _c('van-collapse-item', {
      attrs: {
        "title": "查看备注"
      }
    }, _vm._l(client.orders, function (order, index2) {
      return _c('div', {
        key: index2
      }, [order.remark ? _c('span', {
        staticClass: "print-info-item"
      }, [_vm._v("客户备注：" + _vm._s(order.remark))]) : _vm._e(), order.store_remark ? _c('span', [_vm._v("门店备注：" + _vm._s(order.store_remark))]) : _vm._e()]);
    }), 0) : _vm._e()], 1) : _vm._e()], 1)], 1);
  }), 0)])], 1), _vm.waitCount > 0 ? _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "text": "\u5F00\u59CB\u914D\u9001\uFF08\u5171".concat(_vm.waitCount, "\u4E2A\u5BA2\u6237\uFF0C").concat(_vm.orderCount, "\u5F20\u8BA2\u5355\uFF09")
    },
    on: {
      "click": _vm.begin
    }
  })], 1) : _vm._e(), _vm.showMap ? _c('el-amap', {
    staticClass: "el-map-css",
    attrs: {
      "amap-manager": _vm.amapManager,
      "vid": 'amap-vue',
      "zoom": 18,
      "expand-zoom-range": true,
      "center": [_vm.saveTude.longitude, _vm.saveTude.latitude],
      "events": _vm.events
    }
  }, [_c('el-amap-marker', {
    attrs: {
      "position": [_vm.saveTude.longitude, _vm.saveTude.latitude]
    }
  })], 1) : _vm._e(), _vm.showMap ? _c('van-button', {
    staticClass: "location-map-close-btn",
    attrs: {
      "type": "info",
      "icon": "arrow-left"
    },
    on: {
      "click": function click($event) {
        _vm.showMap = false;
      }
    }
  }, [_vm._v("返回")]) : _vm._e(), _vm.showFixedBtn ? _c('div', {
    staticClass: "fixed-btn-container",
    on: {
      "click": _vm.toPromoteSalesDetailView
    }
  }, [_vm._m(0)]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fixed-btn"
  }, [_c('i', {
    staticClass: "mhj-icon-zhuangche-xian"
  }), _c('span', {
    staticClass: "fixed-btn-text"
  }, [_vm._v("特惠购")])]);
}]

export { render, staticRenderFns }